import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Intro from '../intro-content/intro.component';
import OfferCard from '../offer-post/offerpost.component';
import Subfooter from '../sub-footer/subfooter';
import data from '../../data/offer.json';
import pageData from '../../data/pageData.json';
import './mainbody.style.css';

export default function Main() {
  const ID_FILTER = [1, 2, 3, 4, 5, 6, 7];
  const listicle001 = pageData.Listicles[0].Listicle001[0].IntroDetails;

  const location = useLocation();

  const [incomingParams, setIncomingParams] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const incomingParam1 = searchParams.get('sub1');
    const incomingParam2 = searchParams.get('sub2');
    const incomingParam3 = searchParams.get('sub3');
    const incomingParam4 = searchParams.get('sub4');
    const incomingParam5 = searchParams.get('sub5');

    // Did a console log to confirm if parameters were grabbed
    console.log('Incoming Param 1:', incomingParam1);
    console.log('Incoming Param 2:', incomingParam2);
    console.log('Incoming Param 3:', incomingParam3);
    console.log('Incoming Param 4:', incomingParam4);
    console.log('Incoming Param 5:', incomingParam5);

    // Updated the state of the incoming parameters
    setIncomingParams({ incomingParam1, incomingParam2, incomingParam3, incomingParam4, incomingParam5 });
  }, [location.search]);

  // Filtered and sorted data by id in descending order
  const filteredData = data.filter(i => ID_FILTER.includes(i.id));

  // Defined urlWithParams outside the return statement
  const offerCards = ID_FILTER.map(id => {
    const offer = filteredData.find(i => i.id === id);
    if (!offer) return null;

    const urlWithParams = `${offer.url}?sub1=${incomingParams.incomingParam1}&sub2=${incomingParams.incomingParam2}&sub3=${incomingParams.incomingParam3}&sub4=${incomingParams.incomingParam4}&sub5=${incomingParams.incomingParam5}`;

    // Update the description object with parsed URL values
    const updatedDescription = offer.description.replace(/href='([^']*)'/g, `href='${urlWithParams}'`);

    return (
      <OfferCard
        key={offer.id}
        id={offer.id}
        count={filteredData.length - ID_FILTER.indexOf(id)} // Adjust count value
        title={offer.title}
        image={offer.img}
        description={updatedDescription}
        url={urlWithParams}
        btnTitle={offer.btnTitle}
        rating={offer.rating}
      />
    );
  });

  return (
    <div className="main_container">
      <div className="main_sec_container">
        {listicle001.map(o => (
          <Intro
            key={o.id}
            introTitle={o.introTitle}
            authorName={o.authorName}
            authorImage={o.authorImage}
            introImage={o.introImage}
            introTexts={o.introTexts}
          />
        ))}
        {offerCards}
        <Subfooter />
      </div>
    </div>
  );
}
