import { Divider } from '@material-ui/core'
import React from 'react'
// import author from '../../assets/author-img.jpg'
// import heroImg from '../../assets/hero_img.jpg'
import './intro.style.css'

export default function Intro({...o}) {

    // FUNCTION FOR TODAY'S DATE
    const current = new Date();
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"]
    const date = `${months[current.getMonth()]} ${current.getDate()}, ${current.getFullYear()}`
 

    return (
            <div className="intro_sec">
                <h1>{o.introTitle}</h1>
                <div className="intro_image">
                    <img src={o.introImage} alt="introductory"/>
                </div>
                <div className="intro_details">
                    <div className="author_image">
                        <img src={o.authorImage} alt="author" />
                    </div>
                    <div>
                        <p className="aut_d">
                            Written by <span>{o.authorName}</span> <span className="date">{date}</span> 
                        </p>
                    </div>
                </div>

                <div className="intro_text"> 
                    <p dangerouslySetInnerHTML={{ __html: o.introTexts }} />
                </div>
                <Divider />
            </div>
        )
}
